
.settings-container {
    .settings {
        position: relative;
        .setting {
            margin-bottom: 15px;
            &.active {
                .header {
                    background-color: transparent;
                    .svg-icon {
                        transform: rotate(180deg);
                    }
                }
                .content {
                    display: block;
                    visibility: visible;
                }
            }
            .header {
                display: flex;
                justify-content: space-between;
                background-color: $settingsHeaderBgColor;
                border-bottom: 1px solid $settingsBorderColor;
                padding: 20px 20px 15px 30px;
                cursor: pointer;
                transition: .3s;
                &:hover, &:focus-within {
                    background-color: $settingsHeaderBgColorHover;
                }
                &:focus-within {
                    border: 2px solid $settingsFocusColor;
                }
                h2 {
                    color: $settingsPrimaryFontColor;
                    @media( max-width: 600px ) {
                        font-size: 1.125rem;
                    }
                }
                .svg-icon {
                    display: flex;
                    align-items: center;
                    transition: .3s ease-out;
                    svg {
                        transition: .3s ease-out;
                        fill: $settingsPrimaryFontColor;
                        transform: rotate(-90deg);
                    }
                }
            }
            .content {
                display: none;
                visibility: hidden;
                margin: 35px 0;
                .boxes {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    // margin: -7.5px;
                    >.box {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        padding-top: 45px;
                        width: 100%;
                        max-width: calc(20% - 15px);
                        height: 290px;
                        border: 1px solid $settingsBorderColor;
                        margin: 7.5px;
                        overflow-y: auto;
                        @media( max-width: 1700px ) {
                            max-width: calc(25% - 15px);
                        }
                        @media( max-width: 1500px ) {
                            max-width: calc(33.3% - 15px);
                        }
                        @media( max-width: 900px ) {
                            max-width: calc(50% - 15px);
                        }
                        @media( max-width: 600px ) {
                            max-width: calc(100%);
                        }
                        &:focus-visible {
                            border-color: $settingsFocusColor;
                        }
                        .remove-item {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            top: 10px;
                            right: 20px;
                            color: $settingsSecondaryFontColor;
                            cursor: pointer;
                            border: 2px solid transparent;
                            p {
                                margin-left: 5px;
                            }
                            p, svg path {
                                transition: .3s;
                            }
                            &:focus-visible {
                                border-color: $settingsFocusColor;
                            }
                            &:hover, &:focus-visible {
                                p {
                                    color: $error;
                                }
                                svg {
                                    path {
                                        fill: $error;
                                    }
                                }
                            }
                        }
                        .label-item {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            top: 15px;
                            left: 20px;
                            label {
                                font-weight: bold;
                                font-size: 1rem;
                                color: $settingsPrimaryFontColor;
                            }
                        }
                        .row-image {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 50px;
                            height: 100%;
                            max-height: 155px;
                            @media( max-width: 600px ) {
                                padding: 0 20px;
                            }
                        }
                        .row-input {
                            margin: 10px;
                            .input-wrapper-component {
                                &>label {
                                    font-weight: 500;
                                    padding-left: 2px;
                                    color: $settingsSecondaryFontColor;
                                }
                            }
                        }
                        .row-select-items {
                            padding: 0 20px;
                        }
                        &.select-items-box {
                            max-width: calc(50% - 15px);
                            min-height: 290px;
                            height: 100%;
                            @media( max-width: 900px ) {
                                max-width: 100%;
                            }
                        }
                        &.editor-box {
                            max-width: calc(50% - 15px);
                            overflow-y: inherit;
                            @media( max-width: 900px ) {
                                max-width: 100%;
                            }
                        }
                        &.min-height-box {
                            height: auto;
                        }
                    }
                }
                .add-item-in-section {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
            }
        }
    }
    .save-data {
        margin-top: 45px;
    }
}

.contrast-wcag {
    .settings-container {
        .settings {
            .setting {
                .header {
                    border: none;
                    &:hover, &:focus-within {
                        background-color: $contrastPrimary;
                    }
                    &:focus-within {
                        border: none;

                    }
                    h2 {
                        color: $contrastSecondary;
                    }
                    .svg-icon {
                        svg {
                            fill: $contrastSecondary;
                            path {
                                fill: $contrastSecondary;
                            }
                        }
                    }
                }
                &.active {
                    .header {
                        background-color: $contrastWhite;
                        // &:hover, &:focus-within {
                        //     background-color: $contrastWhite;
                        // }
                        &:focus-visible {
                            background-color: $contrastPrimary;
                        }
                        h2 {
                            color: $contrastSecondary;
                        }
                        .svg-icon {
                            svg {
                                fill: $contrastSecondary;
                                path {
                                    fill: $contrastSecondary;
                                }
                            }
                        }
                    }
                }
                .boxes {
                    .box {
                        border-color: $contrastWhite;
                        &:focus-visible, &:focus-within {
                            border-color: $contrastPrimary;
                        }
                        .remove-item {
                            color: $contrastPrimary;
                            &:focus-visible {
                                border-color: $contrastWhite;
                            }
                            svg {
                                path {
                                    fill: $contrastPrimary;
                                }
                            }
                        }
                        .label-item {
                            label {
                                color: $contrastPrimary;
                            }
                        }
                        .row-input {
                            .input-wrapper-component {
                                &>label {
                                    color: $contrastPrimary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}